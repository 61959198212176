/* Canvas container */
#canvas-container {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

#canvas-container.has-cursor-pointer {
  cursor: pointer;
}

/* Hide/show canvas when scene objects are loading */
.hidden {
  display: none;
}

.shown {
  animation: canvasfadein 1s;
  -webkit-animation: canvasfadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: canvasfadein 1s; /* Firefox < 16 */
  -ms-animation: canvasfadein 1s; /* Internet Explorer */
  -o-animation: canvasfadein 1s; /* Opera < 12.1 */
}
@keyframes canvasfadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes canvasfadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes canvasfadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes canvasfadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes canvasfadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
