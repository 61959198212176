#loading-screen {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}

.pulse-container {
  align-items: baseline;
  display: flex;
  justify-content: center;
}

.loading-text {
  color: white;
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: 700;
  margin: 0 0 15px 0;
}

.pulse-dot {
  background-color: white;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.pulse-dot-1 {
    animation: pulse .4s ease 0s infinite alternate;
}
.pulse-dot-2 {
    animation: pulse .4s ease .2s infinite alternate;
}
.pulse-dot-3 {
    animation: pulse .4s ease .4s infinite alternate;
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}

.progress-bar {
  align-items: center;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 100px;
  display: flex;
  height: 20px;
  padding: 0 5px;
  width: 200px;
}

.progress-value {
  background: #fff;
  border-radius: 100px;
  height: 10px;
}
