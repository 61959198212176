body, html {
  background: #709E7A;
  height: 100%;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
}
